/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react'
import { css, jsx } from '@emotion/react'
<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1"></meta>

const Slide = ({ content, width , matches}) => {

  const config =  {
    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
  };

 /* const handlers = useSwipeable({
    onSwipedLeft: (eventData) => console.log("User Swiped Left!", eventData),
    onSwipedRight: (eventData) => console.log("User Swiped Right!", eventData),
    
    ...config,
  }); */

  return (
    

    <div 
      css={css`
        position: relative;
        ${content.fullScreen === true || !matches ? `top: 0` : `top: 10%`};
        ${content.fullScreen === true || !matches ? `height: 100%` : `height: 80%`};
        width: ${width}px;
        background-image: url('${content.url}');
        ${content.fullScreen === true || !matches ? `background-size: cover` : `background-size: contain`};
        background-repeat: no-repeat;
        background-position: center;
      `}
    >


      </div>
  )
}

export default Slide
