/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import SliderContent from './SliderContent'
import Slide from './Slide'
import Arrow from './Arrow'
import Dots from './Dots'
import Thumbnails from './Thumbnails'
import MainText from './MainText'
import mainImage from './img/mainImage.jpeg'
import { useSwipeable, Swipeable } from "react-swipeable";


/*
const thumbnailsIndexes = [
  0,
  2,
  0,
  2
] */

const thumbnailsCss = [
{top: "53%" , left: "24%" , animationDuration: "10s" , animationName: "drift-1"},
{top: "70%" , left: "85%" , animationDuration: "11s" , animationName: "drift-2"},
{top: "29%" , left: "18%" , animationDuration: "12s" , animationName: "drift-3"},
{top: "34%" , left: "70%" , animationDuration: "13s" , animationName: "drift-4"},
{}]

const getWidth = () => window.innerWidth

/**
 * @function Slider
 */
const Slider = props => {
  const { slides , setActiveSlice , sliderHome , setSliderHome , matches} = props
  const [showUpSlideVal, setShowUpSlide] = useState({...props.showUpSlideVal});

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide()
  });



  let slidesClone = JSON.parse(JSON.stringify(slides))
  //slidesClone = slidesClone.slice(0, 4);

  const thumbnailsIndexes = [
    1,
    Math.floor(slidesClone.length / 4),
    Math.floor(slidesClone.length / 2),
    slidesClone.length - 1
  ] 

  const sliderMainImage = {fullScreen : true , url: mainImage}

  slidesClone.unshift(sliderMainImage);

  

 // console.log("showUpSlideVal = " + showUpSlideVal);

/*    
    'https://images.unspla]sh.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
  'https://images.unsplash.com/photo-1470341223622-1019832be824?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2288&q=80',
  'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
  'https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80'
] */

  const firstSlide = slidesClone[0]
  const secondSlide = slidesClone[1]
  const lastSlide = slidesClone[slidesClone.length - 1]

  const thumbnails = [slidesClone[thumbnailsIndexes[0]] , slidesClone[thumbnailsIndexes[1]] , slidesClone[thumbnailsIndexes[2]] , slidesClone[thumbnailsIndexes[3]]];

  //const doShowUpSlide1 = doShowUpSlide;

  const [state, setState] = useState({
    activeSlide: 0,
    translate: getWidth(),
    transition: 0.45,
    transitioning: false,
    _slides: [lastSlide, firstSlide, secondSlide],
  })

 // const [_showUpSlideVal, setShowUpSlide] = React.useState(showUpSlideVal);
  useEffect(() => { setShowUpSlide(props.showUpSlideVal); }, [props.showUpSlideVal] );



  const { activeSlide, translate, _slides, transition, transitioning } = state

  const autoPlayRef = useRef()
  const transitionRef = useRef()
  const resizeRef = useRef()
  const sliderRef = useRef()
  const throttleRef = useRef()


  const refPassthrough = (el) => {
    // call useSwipeables ref prop with el    
    handlers.ref(el);
    // set the el to a ref you can access yourself
    sliderRef.current = el;
  }


  useEffect(() => {
    autoPlayRef.current = nextSlide
    transitionRef.current = smoothTransition
    resizeRef.current = handleResize
    throttleRef.current = throttleArrows
  })

  useEffect(() => {
    const slider = sliderRef.current

    const smooth = e => {
  //    alert(e.target.id);

      if (e.target.id.includes('sliderContentId')) {
  //      alert("call smoothTransition");
        transitionRef.current()
      }
    }

    const resize = () => {
      resizeRef.current()
    }

    const throttle = e => {
 //     alert("throttle");

      if (e.target.id.includes('sliderContentId')) {
        throttleRef.current()
      }
    }

    const transitionStart = slider.addEventListener('transitionstart', throttle)
    const transitionEnd = slider.addEventListener('transitionend', smooth)
    const onResize = window.addEventListener('resize', resize)


    return () => {
      slider.removeEventListener('transitionend', transitionStart)
      slider.removeEventListener('transitionend', transitionEnd)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    const play = () => {
        autoPlayRef.current();
    }
  
    let interval = null
    
    if (props.autoPlay) {
        interval = setInterval(play, props.autoPlay * 1000);
    }
    
    return () => {
        if (props.autoPlay) {
            clearInterval(interval);
        }
    };
  }, [activeSlide])

  useEffect(() => {

    if (sliderHome) {
      setSlide(0);
      setSliderHome(false);
    }
  }, [sliderHome])


  useEffect(() => {
    if (transition === 0) setState({ ...state, transition: 0.45, transitioning: false })
  }, [transition])




  const throttleArrows = () => {
    setState({...state, transitioning: true })
  } 

  const handleResize = () => {
    setState({ ...state, translate: getWidth(), transition: 0 })
  }

  const nextSlide = () => {
    if(transitioning) return

    setActiveSlice(activeSlide === slidesClone.length - 1 ? 0 : activeSlide + 1);
    setState({
      ...state,
      translate: translate + getWidth(),
      activeSlide: activeSlide === slidesClone.length - 1 ? 0 : activeSlide + 1
    })
  }

  const prevSlide = () => {
 //   alert(transitioning);
    if(transitioning) return

    setActiveSlice(activeSlide === 0 ? slidesClone.length - 1 : activeSlide - 1);

    setState({
      ...state,
      translate: 0,
      activeSlide: activeSlide === 0 ? slidesClone.length - 1 : activeSlide - 1
    })
  }

  const upSlide = () => {
    if(transitioning) return
    console.log("up slide")
    setShowUpSlide(false)

    window.location.href="#mainId";
  }


  const setSlide = (i) => {
    if(transitioning) return
    setActiveSlice(i);

    setState({
      ...state,
      translate: 0,

      activeSlide: i
    })

  }


  const smoothTransition = () => {
//   alert("smoothTransition");
    let _slides = []

    // We're at the last slide.
    if (activeSlide === slidesClone.length - 1)
      _slides = [slidesClone[slidesClone.length - 2], lastSlide, firstSlide]
    // We're back at the first slide. Just reset to how it was on initial render
    else if (activeSlide === 0) _slides = [lastSlide, firstSlide, secondSlide]
    // Create an array of the previous last slide, and the next two slides that follow it.
    else _slides = slidesClone.slice(activeSlide - 1, activeSlide + 2)

    setState({
      ...state,
      _slides,
      transition: 0,
      translate: getWidth()
    })
  }

  if (showUpSlideVal) {
    return (
      
      <div css={SliderCSS} ref={sliderRef} id="mainId">
        <SliderContent
          translate={translate}
          transition={transition}
          width={getWidth() * _slides.length}
        >
          {_slides.map((_slide, i) => (
            <Slide matches={matches} width={getWidth()} key={_slide + i} content={_slide} />
          ))}
        </SliderContent>

        <Arrow direction="up" handleClick={upSlide} /> 
        <Thumbnails thumbnails={thumbnails} thumbnailsCss={thumbnailsCss} handleClick={setSlide} thumbnailsIndexes={thumbnailsIndexes}/>

      </div>
    )} else  {

      if (matches) {
        return (

          <div css={SliderCSS}  {...handlers} ref={refPassthrough} id="mainId">
            <SliderContent
            activeSlide = {activeSlide}
              translate={translate}
              transition={transition}
              width={getWidth() * _slides.length}
            >
              {_slides.map((_slide, i) => (
                <Slide matches={matches} width={getWidth()} key={_slide + i} content={_slide} />
              ))}
            </SliderContent>
    

            <Arrow direction="left" handleClick={prevSlide} />
            <Arrow direction="right" handleClick={nextSlide} />
    
            <Thumbnails thumbnails={thumbnails} thumbnailsCss={thumbnailsCss} handleClick={setSlide} thumbnailsIndexes={thumbnailsIndexes}/>
    
            </div>
          )
        } else {
          return (
            <div css={SliderCSS}  {...handlers} ref={refPassthrough} id="mainId">
            <SliderContent
                translate={translate}
                transition={transition}
                width={getWidth() * _slides.length}
              >
                {_slides.map((_slide, i) => (
                  <Slide matches={matches} width={getWidth()} key={_slide + i} content={_slide} />
                ))}
              </SliderContent>
      
              <MainText activeSlide = {activeSlide}></MainText>
        
              <Thumbnails thumbnails={thumbnails} thumbnailsCss={thumbnailsCss} handleClick={setSlide} thumbnailsIndexes={thumbnailsIndexes}/>
      
              </div>
          )
  
        }
    
    }
}

const SliderCSS = css`
  position: relative;
  max-height: -webkit-fill-available;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: black;  
  overflow: hidden;
  white-space: nowrap;
`

export default Slider




